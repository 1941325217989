<template>
  <div class="pdf-container">
    <!-- 控制面板 -->
    <div class="pdf-controller">
    </div>
    <!-- PDF正文 -->
    <div id="pdfContent" class="pdf-content">
    </div>
  </div>
</template>
<script>
  // console.log("加载pdf开始")
  const PdfJs = require("pdfjs-dist");
  const PdfWorkerJs = '/static/pdf/pdf.worker.min.js'
  PdfJs.GlobalWorkerOptions.workerSrc = PdfWorkerJs;
  // console.log("加载pdf完成")

  export default {
    name: 'PdfPreview',
    components: {},
    data() {
      return {
        pdfJs: PdfJs,
        pdfDoc: null,
        pageRendering: false, // 是否在渲染中
        pageNumPending: null, // 渲染页数
        // pdfFile: "https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf",
      };
    },
    props: {
      pdfFile: String,
    },
    watch: {
      pdfFile(val) {
        this.loadFile(val)
      }
    },
    methods: {
      // 加载文件
      loadFile(fileUrl) {
        this.getBase(fileUrl).then(data => {
          if (this.pdfJs) {
            this.getDocument(data)
          } else {
            console.log("pdf组件加载未完成，请重新刷新页面")
          }
        })
      },

      // 获取blob数据
      getBase(url) {
        return new Promise((resolve, reject) => {
          fetch(url).then(response => response.blob()).then(blob => {
            let reader = new FileReader();
            reader.onloadend = function () {
              resolve(reader.result)
            };
            reader.readAsDataURL(blob);
          }).catch(console.error)
        })
      },

      // 加载文档
      getDocument(pdfResource) {
        this.pdfJs.getDocument(pdfResource).promise.then((pdfDoc) => {
          this.pdfDoc = pdfDoc;
          let numPages = this.pdfDoc.numPages
          if (!numPages || numPages <= 0) {
            return;
          }
          this.pageRendering = true
          for (let num = 1; num <= numPages; num++) {
            console.log(`开始加载第${num}/${numPages}页面`)
            this.pageNumPending = num
            this.renderPage(num);
          }
          this.pageRendering = false
        })
      },
      // 渲染文档
      renderPage(num) {
        this.pdfDoc.getPage(num).then(page => {
          // console.log(page)
          let scale = 1.5
          let viewport = page.getViewport({scale: scale,});
          // Support HiDPI-screens.
          let outputScale = window.devicePixelRatio || 1;

          let pdfContent = document.getElementById('pdfContent');
          let canvas = document.createElement('canvas');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          canvas.setAttribute('style', 'margin: 0 auto;')
          const ctx = canvas.getContext('2d');
          ctx.fillStyle = 'rgba(255, 255, 255, 0)';

          let context = canvas.getContext('2d');
          canvas.width = Math.floor(viewport.width * outputScale);
          canvas.height = Math.floor(viewport.height * outputScale);
          canvas.style.width = Math.floor(viewport.width) + "px";
          canvas.style.height = Math.floor(viewport.height) + "px";
          pdfContent.appendChild(canvas)

          let transform = (outputScale !== 1) ? [outputScale, 0, 0, outputScale, 0, 0] : null;
          let renderContext = {
            canvasContext: context,
            transform: transform,
            viewport: viewport
          };
          page.render(renderContext).then(result => {
            console.log("result", result)
          })
        }).catch(err => {
          console.log(`获取pdf页面第${num}页失败`, err)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pdf-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .pdf-controller {
      height: 40px;
    }

    .pdf-content {
      width: 100%;
      flex: 1;
      overflow: auto
    }
  }
</style>
