<template>
  <div class="layout-vertical">
    <!-- 搜索区域 -->
    <div class="layout-search">
      <div><br></div>
      <div><br></div>
    </div>
    <!-- 功能按钮区域 -->
    <div class="layout-function">
      <a-button type="primary" icon="download" @click="handlePreview">预览PDF</a-button>
      <a-button type="primary" icon="download" @click="handleDownload">下载PDF</a-button>
    </div>
    <!-- 结果区域 -->
    <div class="layout-result p48" style="display: flex;justify-content: center;">
      <!--
      72dpi时候：595 × 842
      -->
<!--      <div ref="pdfContent" style="background-color: #f1f1f1; width: 592px; min-width: 592px; padding: 72px 90px;">-->
      <div ref="pdfContent" style="background-color: #79f3e2; width: 714px; min-width: 714px; padding: 86px 108px;">
        <div style="background-color: #f1f1f1; width: 522px;">
          <div class="doc-row form-row">
            <AmountInput v-model="amountVal"/>
          </div>
          <div class="doc-row form-row">测试页面1测试页面1测试页面1测试页面1</div>
          <div class="doc-row form-row">测试页面2</div>
          <div class="doc-row form-row">测试页面3</div>
          <div class="doc-row form-row">测试页面4</div>
          <div class="doc-row form-row">测试页面4</div>
          <div class="doc-row form-row">测试页面4</div>
          <div class="doc-row form-row">测试页面4</div>
          <div class="doc-row form-row">测试页面4</div>
          <div class="doc-row form-row">测试页面4</div>
          <div class="doc-row form-row">测试页面4</div>
          <div class="doc-row form-row">测试页面4</div>
          <div class="doc-row form-row">测试页面5</div>
          <div class="doc-row form-row">
            测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6
            测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6
            测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6
            测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6测试页面6
          </div>
          <div class="doc-row form-row">测试页面7</div>
          <div class="doc-row form-row">测试页面7</div>
          <div class="doc-row form-row">测试页面7</div>
          <div class="doc-row form-row">测试页面8测试页面8测试页面8测试页面8测试页面8</div>
          <div class="doc-row form-row">测试页面8测试页面8测试页面8测试页面8测试页面8</div>
          <div class="doc-row form-row">
            测试页面9测试页面9测试页面9测试页面9测试页面9测试页面9
            测试页面9测试页面9测试页面9测试页面9测试页面9测试页面9
            测试页面9测试页面9测试页面9测试页面9测试页面9测试页面9
            测试页面9测试页面9测试页面9测试页面9测试页面9测试页面9
            测试页面9测试页面9测试页面9测试页面9测试页面9测试页面9
            测试页面9测试页面9测试页面9测试页面9测试页面9测试页面9
          </div>
        </div>
      </div>
    </div>

    <PdfPreview style="height: 100%; width: 100%;" :pdf-file="pdfFile"/>
    <!-- 添加或修改对话框 -->
  </div>
</template>
<script>
  import PdfPreview from '@/components/Pdf/preview/index.vue'
  import AmountInput from '@/components/AmountInput/index'
  import html2canvas from 'html2canvas'
  import JsPdf from 'jspdf'

  export default {
    name: 'ArticleEdit',
    components: {AmountInput, PdfPreview},
    data() {
      return {
        amountVal: null,
        pdfFile: null
      }
    },
    created() {
      // this.getDetail()
    },
    methods: {
      async getDetail() {
        setTimeout(() => {
          this.pdfFile = "https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf"
        }, 4000)
      },

      handlePreview() {
        this.splitDocRow();
      },
      handleDownload() {
        this.splitDocRow();
        let pdfContent = this.$refs['pdfContent'];
        html2canvas(pdfContent).then(canvas => {
          console.log('---------->', canvas)
          // document.getElementById('canvas').append(canvas)
          let fileName = 'CIPS订单确认函.pdf';
          const doc = new JsPdf('p', 'pt', 'a4');

          // A4在72dpi下的像素
          const a4Width = 595;
          const a4Height = 842;
          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
          // html->canvas缩放比例--->(1785, 714, 595)--->2.5
          // html到canvas放到2.5倍
          // 把2.5倍的image(1785,x)放到pdf(595, 842)上，缩小3倍，即x = 2526
          // 反向计算html的高度 = 2526 / 2.5 = 1010，即html高度 = 1010，可以完整显示在pdf一页上。
          console.log(`缩放比例--->(${contentWidth}, ${pdfContent.offsetWidth}, ${a4Width})--->${contentWidth / pdfContent.offsetWidth}`);
          let pageHeight = contentWidth / a4Width * a4Height;
          console.log(`可以容纳canvas和html高度--->${pageHeight}, ${pageHeight / 2.5}`);
          //未生成pdf的html页面高度
          let leftHeight = contentHeight;
          //页面偏移
          let position = 0;
          //a4纸的尺寸[595, 842]，html页面生成的canvas在pdf中图片的宽高
          let imgWidth = a4Width;
          let imgHeight = a4Width / contentWidth * contentHeight;

          let pageData = canvas.toDataURL('image/jpeg', 1.0);

          //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(842)
          //当内容未超过pdf一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            doc.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              doc.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
              leftHeight -= pageHeight;
              position -= a4Height;
              //避免添加空白页
              if (leftHeight > 0) {
                doc.addPage();
              }
            }
          }
          doc.save(fileName)
          // 由于html改变了，所以需要刷新下
          // document.location.reload()
          let path = this.$route.path
          let query = this.$route.query
          this.$router.replace({path: '/redirect' + path, query: query});
        })
      },
      /**
       * 拆分html的行，可能出现大于一页，那么一行可能被截断，所以保持整行移动到下一页
       */
      splitDocRow() {
        const a4Height = 842;
        // *3/2.5是由上述计算而来
        let htmlContentHeight = (a4Height) * 3 / 2.5 - 86 - 86; // 838
        let headerHeight = 86;
        let footerHeight = 86;
        let docRowList = document.getElementsByClassName('doc-row');
        docRowList = Array.prototype.slice.call(docRowList);
        let sumHeight = 0;
        docRowList.forEach((item, index) => {
          console.log(`${sumHeight}, ${item.offsetHeight}, ${htmlContentHeight}`)
          if(sumHeight + item.offsetHeight <= htmlContentHeight) {
            sumHeight += item.offsetHeight;
          } else {
            // 否则，差额+底部padding的86px，插入一个空白div(上一页页脚+下一页页眉+差值)
            let bankDivHeight = footerHeight + headerHeight + (htmlContentHeight - sumHeight);
            console.log(`需要插入的空白div高--->${bankDivHeight}`);
            let newNode = document.createElement('div');
            newNode.className = 'doc-row';
            newNode.style.width = '100%';
            newNode.style.height = bankDivHeight + 'px';
            // 把新的空白div插入当前前面
            item.parentNode.insertBefore(newNode, item);
            // let nextItem = item.nextSibling;
            // if(nextItem) {
            //  // 把新的空白div插入当前前面
            //  item.parentNode.insertBefore(newNode, item);
            // } else {
            //   item.parentNode.appendChild(newNode)
            // }
            // 重新开始计算高度
            sumHeight = 0;
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .form-row {
    width: 100%;
    padding: 8px 0;
  }
</style>
