<template>
  <div class="input-content">
    <a-input v-model="amountValue"
             :placeholder="placeholder"
             :disabled="disabled"
             :style="styleValue"
             @input.native="handleAmountChange"
             @blur="handleBlur"
    ></a-input>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: '请输入数值'
      },
      styleValue: {
        type: String,
        default: 'width: 150px'
      }
    },
    model:{
      prop: 'amountValue',
      event: 'change',
    },
    data() {
      return {
        amountValue: this.value
      }
    },
    watch: {
      // 默认显示金额
      value(val) {
        this.handleAmountChange(val)
      }
    },
    methods: {
      handleBlur(val) {
        this.$emit('blur', this.amountValue)
      },
      handleAmountChange(val) {
        debugger
        // 去除前后空格
        let value = val.trim()
        // 去除，分隔符
        if (value.indexOf(',') !== -1) {
          value = value.split(',').join('')
          // 防止输入非数字,第一位可为负号
        } else if (isNaN(value) && value !== '-') {
          value = ''
        }
        this.amountValue = value
        this.$emit('change', this.amountValue)
      }
    }
  }
</script>
<style lang='scss' scoped>
  .input-content {
    display: inline-block;
  }
</style>
