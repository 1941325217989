<template>
  <div>
    <div id="map" style="width: 100%; height: 400px"></div>
    <p style="color:gray;font-size:12px;margin:0;height: 25px">如果浏览器无法正确展示地图，请更换其他浏览器操作</p>
  </div>

</template>
<script>
// 引入依赖
// import proj from 'ol'
import { Map, View } from 'ol'
import TileLayer from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ' //xyz
import * as olProj from 'ol/proj'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { defaults as defaultControls } from 'ol/control'
import { Modify } from 'ol/interaction'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
// 定义两个样式：默认样式和鼠标经过时的样式
const defaultStyle = new Style({
  image: new Icon({
    src: '/static/gaode/maker.png',
    scale: 0.2,
  }),
})

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    location: {
      type: Object,
      default: () => {
        return {
          lat: 104.48060937499996,
          lng: 36.30556423523153,
        }
      },
    },
  },
  data () {
    return {
      map: null,
      marker: null,
      vectorSource: null,
      markerPosition: [this.location.lng, this.location.lat],
    }
  },
  // 挂载
  mounted () {
    this.$nextTick(() => {
      this.initMap()
    })
  },
  methods: {
    // 初始化底图map
    initMap () {
      // 初始化地图
      this.vectorSource = new VectorSource({
        features: [],
      })
      this.vectorLayer = new VectorLayer({
        source: this.vectorSource,
      })
      this.map = new Map({
        layers: [
          new TileLayer({
            visible: true,
            source: new XYZ({
              visible: true,
              url: 'http://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}',
              crossOrigin: 'anonymous',
            }),
          }),
          this.vectorLayer,
        ],
        target: 'map',
        view: new View({
          center: this.markerPosition,
          zoom: 13,
          maxZoom: 18,
          projection: 'EPSG:3857',
          constrainResolution: true, // 设置缩放级别为整数
          smoothResolutionConstraint: false, // 关闭无级缩放地图
        }),
        controls: defaultControls({ attribution: false, zoom: false, rotate: false }),
      })
      this.addMaker(this.markerPosition)
      this.addModify()
      // this.map.on('pointermove',  (e)=> {
      //   // 检查当前位置下是否有 marker
      //   this.map.forEachFeatureAtPixel(e.pixel,  (feature)=> {
      //     // 如果鼠标当前位置下有 marker，则改变 marker 的样式
      //     if (feature === this.marker) {
      //       this.marker.setStyle(defaultStyle)
      //     } else {
      //       this.marker.setStyle(defaultStyle)
      //     }
      //   })
      // })
    },
    // 获取输入提示
    getInputtips (keyword, cb) {
      fetch(`https://restapi.amap.com/v3/assistant/inputtips?key=ed0cd4f75e34bbe025e6d4ca9166568b&keywords=${keyword}`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
      })
        .then((res) => {
          return res.text()
        })
        .then((data) => {
          let result = JSON.parse(data)
          cb && cb(result.tips)
        })
    },
    setCenter (coord, type = 1) {
      let transformedCoord = []
      if (coord[0] && coord[1]) {
        transformedCoord = olProj.fromLonLat(coord)
      } else {
        transformedCoord = this.markerPosition
      }
      this.$nextTick(() => {
        this.map.getView().setCenter(transformedCoord)
        if (this.marker) {
          this.marker.getGeometry().setCoordinates(transformedCoord)
        }
      })
    },
    setZoom (zoom) {
      this.map.getView().setZoom(zoom)
    },
    addMaker (markerCoordinate) {
      this.marker = new Feature({
        geometry: new Point(markerCoordinate),
      })
      this.marker.setStyle(defaultStyle)
      this.vectorSource.addFeature(this.marker)
    },
    addModify () {
      const modify = new Modify({
        source: this.vectorSource, // 使用相同的矢量源
      })
      this.map.addInteraction(modify)

      // 监听 modifyend 事件来获取新的坐标
      modify.on('modifyend', (e) => {
        const newCoordinate = this.marker.getGeometry().getCoordinates()
        const newLonLat = olProj.toLonLat(newCoordinate) // 转换为经纬度
        console.log('New coordinate:', newLonLat)

        this.getAddress(newLonLat)
      })
    },
    getAddress (coordinate) {
      let location = coordinate.join(',')
      fetch(`https://restapi.amap.com/v3/geocode/regeo?key=ed0cd4f75e34bbe025e6d4ca9166568b&location=${location}&radius=10&roadlevel=0`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
      })
        .then((res) => {
          return res.text()
        })
        .then((data) => {
          let result = JSON.parse(data)
          console.log(result)
          let e = {
            address: result.regeocode.formatted_address,
            lat: coordinate[1],
            lng: coordinate[0],
          }
          this.$emit('move', e)
        })
    },
  },
}
</script>
